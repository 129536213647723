import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39')
];

export const server_loads = [];

export const dictionary = {
		"/": [16],
		"/(authenticated)/account": [17,[2,3]],
		"/(authenticated)/account/[tab]": [18,[2,3,4]],
		"/confirm": [31],
		"/(authenticated)/dashboard": [19,[2]],
		"/(authenticated)/experimental": [20,[2,5]],
		"/(authenticated)/experimental/agents": [21,[2,5,6]],
		"/(authenticated)/experimental/agents/[tab]": [22,[2,5,6]],
		"/(authenticated)/experimental/hewsync": [23,[2,5,7]],
		"/forgot-password": [32],
		"/iframe": [33],
		"/login-as": [34],
		"/logout": [35],
		"/markdown-preview": [36],
		"/reset-password": [37],
		"/share": [38,[],[15]],
		"/sign-up": [39],
		"/(authenticated)/[organization=organization]": [24,[2,8]],
		"/(authenticated)/[organization=organization]/api": [25,[2,8,9]],
		"/(authenticated)/[organization=organization]/api/[kind]/[id]/[[tab]]": [26,[2,8,9,10,11]],
		"/(authenticated)/[organization=organization]/dashboard": [27,[2,8]],
		"/(authenticated)/[organization=organization]/project/[id]/[...page]": [28,[2,8,12]],
		"/(authenticated)/[organization=organization]/[slug=perms]": [29,[2,8,13]],
		"/(authenticated)/[organization=organization]/[slug=perms]/[id]/[[tab]]": [30,[2,8,13,14]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';